import { useLocation } from "@reach/router";
import React from "react";
// import PropTypes from "prop-types";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from 'react-accessible-accordion';

import 'react-accessible-accordion/dist/fancy-example.css';


const AccordionContent = ({ accordionHeading, productThemeColor, id, children }) => {

  const location = useLocation();

  const preExpanded = location?.hash?.replace('#', '') === id ? [id] : []

  return (
    <div className={productThemeColor}>
      <Accordion allowZeroExpanded preExpanded={preExpanded}>
        <AccordionItem id={id} uuid={id}>
          <AccordionItemHeading>
            <AccordionItemButton
              dangerouslySetInnerHTML={{ __html: accordionHeading }} />
          </AccordionItemHeading>
          <AccordionItemPanel>
            {children}
          </AccordionItemPanel>
        </AccordionItem>
      </Accordion>
    </div>
  )
};
export default AccordionContent;